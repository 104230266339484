import {Navigate, useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {isString} from 'lodash'
import {useMemo} from 'react'

export const guestOnly = Component => {
  return props => {
    const user = useSelector(state => state.authentication.user)
    const location = useLocation()
    const scannedCodePath = ['pet-found?code', 'pets/create', 'scan-report/create']
    const isScannedPath = path => scannedCodePath.some(ele => path.includes(ele))

    const isCodeScanned = useMemo(() => {
      return location?.state?.from && isString(location?.state?.from) && isScannedPath(location?.state?.from)
        ? true
        : false
    }, [location])

    const renderComponent = () => {
      if (!user) {
        return <Component {...props} />
      } else if (isCodeScanned) {
        console.log('tag-scanned')
        return user?.firstName && user?.lastName && user?.email
          ? <Navigate to={location?.state?.from} replace />
          : <Navigate to="/basic-detail" state={{from: location?.state?.from}} replace />
      } else if (!user?.firstName || !user?.lastName || !user?.email)  {
        return <Navigate to="/basic-detail" replace />
      } else {
        return <Navigate to="/wallet" state={{from: location}} replace />
      }
    }

    return renderComponent()
  }
}
