export const PrivacyPolicy = () => {
  return (
    <div className="!mt-2 flex w-full justify-center items-center ">
      <a href="" style={{color: '#ED1690'}}>
        Privacy policy
      </a>
      <span className="mx-4"></span>
      <a href="" style={{color: '#ED1690'}}>
        Data policy
      </a>
    </div>
  )
}
