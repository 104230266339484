import React from 'react'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import {AuthActions} from 'store/modules/authentication/actions'
import LogoutIcon from '@mui/icons-material/Logout';

const Header2 = (props) => {
  const {  open, heading } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logout = () => {
    // closeMenu()
    dispatch(AuthActions.resetAuth())
    localStorage.removeItem('access_token')
    navigate('/authentication/login', {replace: true})
  }

  return (
    <div>
      <MuiAppBar position="fixed" open={open} color='header'>
        <Toolbar>
          <Grid container  direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" noWrap component="h6" color="dark.contrastText">
              {heading}
            </Typography>
            <IconButton onClick={logout}>
              <LogoutIcon style={{color: 'white'}}/>
            </IconButton>
          </Grid>
        </Toolbar>
      </MuiAppBar>
    </div>
  )
}

export default Header2;
