import {Box, IconButton, Typography} from '@mui/material'
import Close from '@mui/icons-material/Close'

const editAdd = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,

  width: 600,

  '@media (max-width:767px)': {
    width: '95%'
  }
}

export const ModalLayout = props => {
  const {title, loading = false, onClose = () => {}} = props

  return (
    <Box sx={editAdd}>
      <Box sx={{p: {lg: 5, md: 5, sm: 4, xs: 4}}}>
        <Box display="flex" alignItems="center" justifyContent="space-between" pb={2.5}>
          <Typography
            variant="h5"
            component="h5"
            color="transparent.contrastText"
            sx={{fontSize: '24px', fontWeight: '600'}}
            style={{width: '100%'}}
          >
            {title}
          </Typography>
          <Box textAlign="right" width="100%">
            <IconButton onClick={onClose}>
              <Close onClick={onClose} />
            </IconButton>
          </Box>
        </Box>
        {loading && props?.LoaderComponent ? <props.LoaderComponent /> : props.children}
      </Box>
    </Box>
  )
}
