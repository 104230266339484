import React, { useEffect, useState } from 'react';
import {  Button,  TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import appLogo from 'images/app_logo.png'
import {useSelector} from 'react-redux'
import { socketClient } from 'client/socket.client';
import { RequestSubmittedModal } from './RequestSubmittedModal';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
// import { NumberTextField } from 'components/NumberTextField';

// const phoneRegex = /^\+?[0-9]*$/;

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  // phone: Yup.string().matches(/^\+[0-9]+$/, 'Invalid Phone number. Please enter country code first eg. +1')
});

const HyperLink = ({title, href}) => {
  return <Link href={href} className="!text-blue-700" target="_blank" underline="none">{title}</Link>
}

export const Enroll = () => {
  const user = useSelector(state => state?.authentication?.user)
  const [open, setOpen] = useState(false)
  const [termCondition, setTermsCondition] = useState(true)
  const handleSubmit = (values) => {
    socketClient.service('enroll')
      .create({
        data: {
          enrollData:{...values},
          verified: false,
          type: 'personal'
        }
      })
      .then(result => {

        if (result) {
          socketClient.io.emit('submit', 'enroll', {
            _id: result?._id,
          }, {}, (error, data) => {

            if (error) {
              if (error) {
                console.log(error)
              }
              return
            }
            setOpen(true)
            console.log(data)
          })
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      // phone: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (user) {
      formik.setValues({firstName: user?.firstName, lastName: user?.lastName, email: user?.email})
    }
  }, [user])

  return (
    <div className="w-full flex flex-col lg:mt-8 justify-center lg:justify-start items-center p-4">
      <img src={appLogo} alt="Noah'sArk"/>
      <div
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: '8px'
        }}
        className="flex flex-col p-4 lg:p-8 w-full md:w-1/2 lg:w-1/2 xl:w-1/3"
      >
        <p className="font-bold text-center pl-2 mb-2">Pet Finder Personal Enroll</p>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label="First Name"
            size='small'
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            margin="normal"
          />
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            size='small'
            label="Last Name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            margin="normal"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            size='small'
            label="Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          {/* <NumberTextField
            fullWidth
            id="phone"
            name="phone"
            size='small'
            label="Phone"
            value={formik.values.phone}
            onInput={event => {
              event.target.value = event.target.value.replace(/^-/, '')
            }}
            onChange={(e) => {
              if (phoneRegex.test(e.target.value)) {
                formik.setFieldValue('phone', e.target.value)
              }
            }}
            inputProps={{ inputMode: 'numeric' }}
            margin="normal"
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          /> */}
          <div className='text-start'>
            <Checkbox checked={termCondition} onChange={e => setTermsCondition(e.target.checked)} size="small" className="!p-0"/>
            <span className='text-sm ml-2'>By checking this box you agree to Noah's Ark Pet Connect LLC <HyperLink href="#" title="Terms of Service" /> and <HyperLink href="#" title="Privacy Policy"/>. as well as our Vendor Dwolla's <HyperLink href="#" title="Terms and Conditions"/> and <HyperLink href="#" title="Privacy Policy" />.</span>
          </div>
          <Button type="submit" variant="contained" color="primary" style={{ marginTop: 16 }} disabled={!termCondition}>
            Submit
          </Button>
        </form>
        <RequestSubmittedModal open={open} setOpen={setOpen}/>
      </div>
    </div>
  )
}
