import {useState} from 'react'
import {AUTHENTICATION_KEY} from 'client/common'
import axios from 'axios'

export const useMediaUpload = () => {
  const [loading, setLoading] = useState(false)

  const headers = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    Authorization: window.localStorage.getItem(AUTHENTICATION_KEY)
  }

  const uploadMedia = (file, path = null, isStore = false) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      const formData = new FormData()
      formData.append('files', file)

      if (path) formData.append('path', path)

      axios
        .post(
          `${isStore ? process.env.REACT_APP_STORE_MODULE_BASE_URL : process.env.REACT_APP_BASE_URL}/media`,
          formData,
          {headers}
        )
        .then(result => resolve(result.data))
        .catch(e => {
          reject(e)
        })
        .then(() => setLoading(false))
    })
  }

  return { loading, uploadMedia }
}
