import { LoadingDialog } from "components/LodingDialog"
import { useEffect } from "react"
import { useParams } from "react-router";

export const Referral = () => {
  const params = useParams();

  useEffect(() => {
    if (!params?.code) return
    window.location = `noahsark://referral/${params.code}`

    setTimeout(() => {
      window.location = `${process.env.REACT_APP_BASE_URL}/referral/${params.code}`
    }, 3000)

  }, [params])

  return <LoadingDialog />
}
