import {useEffect, useState} from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import './map.css'
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded'
import {renderToString} from 'react-dom/server'
import PropTypes from 'prop-types'
import {Button} from '@mui/material'

/**
 * PetLostTimeLine
 *
 * @param {double} lat - latitude
 * @param {double} lng - longitude
 * @param {array} scans - Array of pet scan object
 */

export const LostPetMap = props => {
  const {lat = 0, lng = 0, scans, width = '100%', height = '100%'} = props
  const [pinState, setPinState] = useState({lat: 0, lng: 0})
  const [pinPoints, setPinPoints] = useState([])
  const [reCenter, setReCenter] = useState(false)

  useEffect(() => {
    if (scans && scans?.length > 0) {
      setPinPoints(scans)
    }
  }, [scans])

  useEffect(() => {
    setPinState({lat, lng})
  }, [lat, lng])

  useEffect(() => {
    if (pinPoints?.length > 0 || reCenter) {
      setReCenter(false)
      const map = L.map('map').setView([pinState.lat, pinState.lng], 13)
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(map)

      const grayIcon = L.divIcon({
        className: 'custom-icon',
        html: renderToString(<FmdGoodRoundedIcon style={{color: '#808080'}} />),
        iconSize: [32, 32]
      })

      const icon = L.divIcon({
        className: 'custom-icon',
        html: renderToString(<FmdGoodRoundedIcon style={{color: '#ED1690'}} />),
        iconSize: [32, 32]
      })

      for (let i = 0; i < pinPoints.length; i++) {
        const pin = pinPoints[i]
        const label = `${pin?.by?.firstName ? pin?.by?.firstName : ''} ${
          pin?.by?.lastName ? pin?.by?.lastName : ''
        }<br> ${pin?.note ? pin?.note : ''}`
        const lat = pin?.location[1]
        const lng = pin?.location[0]
        const marker = L.marker([lat, lng], {icon: pinPoints.length - 1 === i ? icon : grayIcon})
        if (lat === pinState?.lat && lng === pinState?.lng) {
          marker.addTo(map).bindPopup(label).openPopup()
        } else {
          marker.addTo(map).bindPopup(label)
        }
      }

      return () => {
        map.remove()
      }
    }
  }, [pinPoints, pinState, reCenter])

  return (
    <div style={{width: width, height: height, position: 'relative'}}>
      <div id="map" style={{width: width, height: height, borderRadius: '10px'}} />
      <Button
        className="!font-bold !absolute !bottom-2.5 !right-2.5 !z-[1000]"
        variant="contained"
        color="primary"
        onClick={() => setReCenter(true)}
      >
        Re-Center
      </Button>
    </div>
  )
}

LostPetMap.prototype = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  scans: PropTypes.array
}
