import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, FormControl,OutlinedInput } from '@mui/material';
import {Button, CircularProgress, Typography, Modal} from '@mui/material';
import playstore from 'images/playstore.svg'
import appstore from 'images/appstore.svg'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from 'react-redux'
import { PetFoundActions } from 'store/modules/pet-found-report/actions';
import { AuthActions } from 'store/modules/authentication/actions';
import {useSelector} from 'react-redux'
import { socketClient } from 'client/socket.client';
import { SnackbarContext } from 'components/snackbar/SnackbarContext';
import { AppDownloadButton } from 'components/AppDownloadButton';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  padding: '24px',
  width: 500,

  '@media (max-width:767px)': {
    width: '95%'
  }
}

const EnrollNotifyModal = ({ open, onClose }) => {
  const navigate= useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(state => state?.authentication?.user)
  const [payload, setPayload] = useState(null)
  const {setSeverity, showToast} = useContext(SnackbarContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user) {
      const {firstName, lastName, email} = user
      setPayload({firstName, lastName, email, relationId: user?._id})
    }
  }, [user])

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleContinue = async () => {
    if (!payload || !isValidEmail(payload?.email)) {
      setSeverity('error')
      showToast('Invalid Email')
      return
    }

    setLoading(true)

    if (user && !user?.email && payload?.email) {
      await socketClient.service('users').patch(user?._id, {email: payload?.email})
    }

    socketClient.service('enroll')
      .create({
        data: {
          enrollData:{...payload},
          verified: false,
          type: 'personal'
        }
      })
      .then(result => {
        if (result) {
          socketClient.io.emit('submit', 'enroll', {
            _id: result?._id,
          }, {}, (error, data) => {
            setLoading(false)
            if (error) {
              setSeverity('error')
              showToast(error?.message ? error?.message : 'Oops, failed to submit application')
              console.log(error)
              return
            }

            navigate('/enroll/create-funding-source', {replace: true})
          })
        }
      })
      .catch(e => {
        console.log(e)
        setSeverity('error')
        showToast('Oops, failed to submit application')
        setLoading(false)
      })
  }

  const handleClose = (event, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return
    dispatch(PetFoundActions.resetPetFound())
    localStorage.removeItem('auth')
    dispatch(AuthActions.resetAuth())
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography
          variant="h5"
          component="h5"
          color="transparent.contrastText"
          sx={{fontSize: '24px', fontWeight: '600'}}
          style={{width: '100%', textAlign: 'start'}}
        >
          Open your wallet to claim any earned rewards.
        </Typography>
        <Box display="flex" flexDirection="column"  mt={4} mb={2} sx={{textAlign: 'start'}}>
          <Typography
            className="!text-gray-500"
            sx={{fontSize: '12px', textAlign: 'start'}}
          >
            Upon successfully reuniting a Pet Owner with their Lost Pet you may earn some Lost Pet rewards. Setup your wallet to claim any earnings.
          </Typography>

          <Typography
            className="!text-gray-500 !mt-1"
            sx={{fontSize: '12px', textAlign: 'start'}}
          >
            Name: <span className="font-bold text-gray-600">{payload?.firstName} {payload?.lastName}</span>
          </Typography>

          <Typography
            className="!text-gray-500 !mb-1"
            sx={{fontSize: '12px', textAlign: 'start'}}
          >
            Email: <span className="font-bold text-gray-600">{payload?.email ? payload.email : `You'll be asked next`}</span>
          </Typography>

          <Typography
            sx={{fontSize: '12px', textAlign: 'start'}}
            className="!text-gray-500"
          >
            By continuing , you agree to the terms and conditions, and privacy policy.
          </Typography>

          {!user?.email && <FormControl sx={{mt: 2, width: '100%'}} variant="outlined">
            <OutlinedInput
              size="small"
              placeholder="Enter the email you want to use with wallet"
              onChange={e => setPayload({...payload, email: e.target.value})}
            />
          </FormControl>}

          <div className="flex w-full justify-between mb-3 mt-5">
            <Button
              variant='contained'
              size='small'
              color='dark'
              sx={{textTransform: 'none', mr: 2, width: '50%', fontWeight: 'bold'}}
              onClick={handleClose}
            >
              Not now, I'll do it later!
            </Button>

            <Button
              variant='contained'
              size='small'
              color='primary'
              sx={{textTransform: 'none', ml:2, width: '50%', fontWeight: 'bold'}}
              onClick={handleContinue}
            >
              Yes, Open my wallet {' '}
              {loading && <CircularProgress style={{width: '18px', height: '18px', marginLeft: '8px'}} />}
            </Button>
          </div>
        </Box>
        <Divider sx={{mb: 2}}/>
        <div className="flex justify-between items-center">
          <a href="https://internal.petflix.io/" className="!text-gray-600 !text-xs underline" target="_blank" rel="noreferrer" >Terms of service</a>
          <div className="flex justify-center">
            <AppDownloadButton
              icon={appstore}
              description="Download on the"
              platform="AppStore"
              link="https://www.apple.com/in/app-store"
            />
            <AppDownloadButton
              icon={playstore}
              description="GET IT ON"
              platform="Google play"
              link="https://play.google.com/store/games?hl=en-IN"
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EnrollNotifyModal;
