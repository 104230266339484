import { Grid, Box, Avatar, Typography, Card} from '@mui/material';
import placeholder from 'images/avatar_placeholder.svg'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { pink } from '@mui/material/colors';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
import Header2 from "components/Header2";
import { useEffect, useState } from "react";
import {useSelector} from 'react-redux'
import { reduce } from "lodash";
import axios from "axios";
import { GET_ACCOUNTS, GET_DWOLLA_ACCESS_TOKEN, GET_DWOLLA_ID, GET_PLAID_BALANCE, IPAY_NET_BASE_URL, currency, enrollHeader } from "utils/Constants";
import { LoadingDialog } from "components/LodingDialog";
import {useNavigate} from 'react-router-dom'
import PetsIcon from '@mui/icons-material/Pets';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { FundingSourceCard } from './components/FundingSourceCard';
import { SendMoneyModel } from './model/send-money/SendMoneyModel';

export const Dashboard = () => {
  const user = useSelector(state => state?.authentication?.user)

  const [walletBalance, setWalletBalance] = useState(0)
  const [loading, setLoading] = useState(false)
  const [openSendModal, setSendModal] = useState(false)
  const [fundingSources, setFundingSources] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        setLoading(true)
        const dwollaResult = await axios.post(`${IPAY_NET_BASE_URL}/${GET_DWOLLA_ID}/${user?._id}`, {}, {headers: enrollHeader})
        console.log('dwollaResult', dwollaResult)
        if (dwollaResult.status === 200 && dwollaResult?.data) {
          const accountsResult = await axios.post(`${IPAY_NET_BASE_URL}/${GET_ACCOUNTS}`, {dwollaId: dwollaResult?.data}, {headers: enrollHeader})
          console.log('accountsResult', accountsResult)
          if (accountsResult.status === 200 && accountsResult?.data?.length) {
            const accounts = accountsResult?.data.map((account) => account?.plaid_id)
            console.log('accounts', accounts)
            if (accounts?.length) {
              const accessToken = await axios.post(`${IPAY_NET_BASE_URL}/${GET_DWOLLA_ACCESS_TOKEN}`, {dwollaId: dwollaResult?.data}, {headers: enrollHeader})
              console.log('accessToken', accessToken)
              if (accessToken.status === 200 && accessToken?.data) {
                const accountBalances = await axios.post(`${IPAY_NET_BASE_URL}/${GET_PLAID_BALANCE}`, {accessToken : accessToken?.data, accounts}, {headers: enrollHeader})
                console.log('accountBalances', accountBalances)
                if (accountBalances?.status === 200 && accountBalances?.data) {
                  const balance = reduce(accountBalances?.data?.accounts, function(acc, account) {
                    return acc + account?.balances?.available
                  }, 0)
                  setWalletBalance(balance)
                  setFundingSources(accountBalances?.data)
                  setLoading(false)
                }
              }
            }
          }
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }
    if (user) fetchBalance()
  }, [user])

  return loading ? (<LoadingDialog />) : (
    <Box sx={{backgroundColor: 'rgb(247, 247, 247)', height: '100vh'}}>
      <Header2 heading="My Wallet"/>

      <Box sx={{px: {lg: '4rem', xs: '1rem'}, mt: '5rem'}}>
        <div className='flex mb-4'>
          <AccountBalanceWalletIcon sx={{ fontSize: 32, color: pink[500]  }}/>
          <Typography sx={{fontWeight: 'bold', fontSize: '24px', textAlign: 'left', mx:'8px'}}>Wallet</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item lg={4.5} md={6} sm={12} xs={12}>
            <Card>
              <Box sx={{ display: 'flex', p: '16px', alignItems: 'center', height: '100%' }}>
                <Avatar src={placeholder} sx={{ width: 48, height: 48 }}></Avatar>

                <Box ml="12px" flex="1 1 0">
                  <Box sx={{display: 'flex', justifyContent:'space-between', alignItems: 'center', flexWrap: 'wrap', textAlign:'left'}}>
                    <div>
                      <Typography sx={{fontWeight: 'bold'}}>{user?.firstName ? user?.firstName : ''} {user?.lastName ? user?.lastName : ''}</Typography>
                      <Typography sx={{fontSize: '12px'}}>{user?.email ? user?.email : ''}</Typography>
                      <Typography sx={{fontSize: '12px'}}>{user?.phone ? user?.phone : ''}</Typography>
                    </div>
                    <Typography sx={{fontWeight: 'bold', fontSize: '24px', color: pink[500] }}>{currency(walletBalance)}</Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item lg={1.5} sm={6} xs={6}>
            <Card onClick={() => setSendModal(true)}>
              <Box
                sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', p: '1rem 1.25rem'}}
              >
                <SwapHorizIcon sx={{ fontSize: 32, color: pink[500] }} />
                <Typography sx={{fontSize: '12px'}}>Transfer</Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item lg={1.5} sm={6} xs={6}>
            <Card onClick={() => navigate('/wallet/activity')}>
              <Box
                sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', p: '1rem 1.25rem'}}
              >
                <AccountBalanceWalletIcon sx={{ fontSize: 32, color: pink[500]  }}/>
                <Typography sx={{fontSize: '12px'}}>My Wallet</Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item lg={1.5} sm={6} xs={6}>
            <Card onClick={() => setSendModal(true)}>
              <Box
                sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', p: '1rem 1.25rem'}}
              >
                <PetsIcon sx={{ fontSize: 32, color: pink[500] }} />
                <Typography sx={{fontSize: '12px'}}>Lost Pet</Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item lg={1.5} sm={6} xs={6}>
            <Card>
              <Box
                sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', p: '1rem 1.25rem'}}
              >
                <PaidIcon sx={{ fontSize: 32, color: pink[500]  }}/>
                <Typography sx={{fontSize: '12px'}}>Request & Pay</Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item lg={1.5} sm={6} xs={6}>
            <Card>
              <Box
                sx={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', p: '1rem 1.25rem'}}
              >
                <GroupsIcon sx={{ fontSize: 32, color: pink[500]  }}/>
                <Typography sx={{fontSize: '12px'}}>Invite Friends</Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Typography sx={{textAlign: 'left', mt: '2rem', mb: '1rem', fontWeight: 'bold', fontSize: '16px'}}>
          <AccountBalanceIcon color='primary' sx={{mr: '8px'}}/>My Funding Sources
        </Typography>
        <Grid container spacing={2}>
          {fundingSources?.accounts?.length !== 0 && fundingSources?.accounts?.map((item, index) => (
            <FundingSourceCard item={item} key={index} />
          ))}
        </Grid>
      </Box>

      <SendMoneyModel open={openSendModal} onClose={() => setSendModal(false)}/>
    </Box>
  )
}
