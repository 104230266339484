import './App.css'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {Auth} from './modules/auth/Auth'
import {Theme} from './Theme.js'
import {SnackbarContextProvider} from 'components/snackbar/SnackbarProvider'
import {PetFoundPage} from 'modules/pet-found'
import {SetupBasicDetail} from 'modules/auth/SetupProfileDetail'
import {ScanReport} from 'modules/pet-found/scan-report'
import { Enroll } from 'modules/pet-found/enroll'
import { AddFundingSource } from 'modules/enroll/AddFundingSource'
import { Wallet } from 'modules/wallet'
import { Referral } from 'modules/referral'
function App() {
  return (

      <div className="App h-full">
        <Theme>
          <SnackbarContextProvider>
            <BrowserRouter>
              <Routes>
                <Route index path="/" element={<Wallet />} />
                <Route path="/authentication/*" element={<Auth />} />
                <Route path="/pet-found" element={<PetFoundPage />} />
                <Route path="/basic-detail" element={<SetupBasicDetail />} />
                <Route path="/scan-report/*" element={<ScanReport />} />
                <Route path="/enroll" element={<Enroll />} />
                <Route path="/enroll/create-funding-source" element={<AddFundingSource />} />
                <Route path="/wallet/*" element={<Wallet />} />
                <Route path="/referral/:code" element={<Referral />} />
              </Routes>
            </BrowserRouter>
          </SnackbarContextProvider>
        </Theme>
      </div>
  )
}

export default App
